import baguetteBox from './baguetteBox.js';
//import Waypoints from './waypoints.js';
import { initScrollUpBtn } from './scrollup.js';



function swipedetect() {

	//document.addEventListener('touchstart', handleTouchStart, false);
	//document.addEventListener('touchmove', handleTouchMove, false);
	var touchelement = document.querySelector("body");
	touchelement.addEventListener('touchstart',handleTouchStart,false);
	touchelement.addEventListener('touchmove', handleTouchMove, false);

	var xDown = null;
	var yDown = null;

	function handleTouchStart(evt) {
	    xDown = evt.touches[0].clientX;
	    yDown = evt.touches[0].clientY;
	};

	function handleTouchMove(evt) {
	    if ( ! xDown || ! yDown ) {
	        return;
	    }

	    var xUp = evt.touches[0].clientX;
	    var yUp = evt.touches[0].clientY;

	    var xDiff = xDown - xUp;
	    var yDiff = yDown - yUp;

	    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
	        if ( xDiff > 0 ) {
							/* left swipe */
              document.body.classList.remove("show-navigation");
	        } else {
	            /* right swipe */
              document.body.classList.add("show-navigation");
	        }
	    } else {
	        if ( yDiff > 0 ) {
	            /* up swipe */
	        } else {
	            /* down swipe */
	        }
	    }
	    /* reset values */
	    xDown = null;
	    yDown = null;
	};
};




function setupWaypoints(elementSelector,showElementSelector,addRemoveClass){
	 let waypoint = new Waypoint({
		element: document.querySelector(elementSelector),
		handler: function(direction) {
			let shareButtons = document.querySelector(showElementSelector);
			if(direction === 'down') {
				shareButtons.classList.add(addRemoveClass);
			} else {
				shareButtons.classList.remove(addRemoveClass);
			}
		}
	});
}



document.addEventListener('DOMContentLoaded', function(event) {
  	'use strict';
		/*
    window.addEventListener('scroll',function(){
      if(window.scrollY > 100) {
        document.querySelector('.back-to-top').classList.add('fade-in');
      } else {
        document.querySelector('.back-to-top').classList.remove('fade-in');
      }
    });
*/
  /*  document.querySelector('.back-to-top').addEventListener('click',function(event){
      event.preventDefault();
      scrollTo(document.querySelector('html,body'),800,'easeInOutQuad');
    }); */


		document.querySelector('.nav-trigger').addEventListener('click',function(event){
      event.preventDefault();
      document.body.classList.toggle("show-navigation");
    });
    swipedetect();

		// lightbox
	baguetteBox.run('.ce_gallery, .ce_image [data-lightbox], .ce_text [data-lightbox] ', {
		animation: 'fadeIn',
    noScrollbars: false
	});




	//setupWaypoints("#main .mod_article .block:not(:first-child)","#right .mod_article","show-fixed");



});

initScrollUpBtn();
